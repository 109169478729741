var hamburger = document.getElementById("nav-icon");
var header = document.getElementById("header");
var mobileMenu = document.getElementById("mobile-menu");
var scrollPosition = 0;

function toggleMenu() {
    hamburger.classList.toggle("open");
    mobileMenu.classList.toggle("active");
    console.log(scrollPosition);
    if (scrollPosition < 10) {
        header.classList.toggle("scroller");
    }
}

hamburger.addEventListener('click', toggleMenu);


document.addEventListener("scroll", () => {
    scrollPosition = window.scrollY;

    if (scrollPosition > 10) {
        if (!header.classList.contains("scroller")) {
            header.classList.add("scroller")
        }
    } else {
        if (header.classList.contains("scroller") && !hamburger.classList.contains("open")) {
            header.classList.remove("scroller")
        }
    }
});



const overlay = document.getElementById("formOverlay");
const formClose = document.getElementById("formClose");
const form = document.getElementById("form");


formClose.addEventListener("click", function() {
    overlay.style.display = "none";
    form.removeEventListener("submit", formSubmit);
});

const formOpen = document.getElementsByClassName("openForm");
for (let i = 0; i < formOpen.length; i++) {
    formOpen[i].addEventListener("click", function() {
        overlay.style.display = "flex";
        form.addEventListener("submit", formSubmit);
    });
}





const alertContainer = document.getElementById("alert");

function formSubmit(e:any) {
    alertContainer.classList.remove("success");
    alertContainer.classList.remove("danger");
    alertContainer.innerText = "";
    e.preventDefault();
    const formData = new FormData(e.target);

    fetch("https://getform.io/f/3181d777-c183-46c1-9deb-bb6c37db5c5a", {
        method: "POST",
        body: formData,
        headers: {
            "Accept": "application/json",
        },
    })
        .then(response => {
            alertContainer.classList.add("success");
            alertContainer.innerText = "Tack, vi hör av oss så snart vi kan!";
            form.style.display = "none;"
        })
        .catch(error => {
            alertContainer.classList.add("danger");
            alertContainer.innerText = "Oj då, nu gick något fel!";
        })
}